import { Link } from "react-router-dom";
import VehicleCard from "./VehicleCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules"; // Import Autoplay module
import "swiper/css";

function VehicleList({ vehicles }) {
  return (
    <>
      {/* eg.featured cars */}
      <div className=" max-w-[1400px] mx-auto my-16 px-4">
        <h4 className="font-bold text-3xl my-4">Featured</h4>
        <Swiper
          spaceBetween={50}
          slidesPerView={3}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          loop={true}
          breakpoints={{
            200: { slidesPerView: 1 }, // Small screens
            768: { slidesPerView: 2 }, // Medium screens
            1024: { slidesPerView: 3 }, // Large screens
          }}
          autoplay={{
            delay: 1000, // Delay between transitions in milliseconds
            disableOnInteraction: false, // Continue autoplay even after interaction
          }}
          modules={[Autoplay]}
        >
          {vehicles?.map((item) => (
            <SwiperSlide>
              <Link
                to={`/cars/${item._id}`}
                onClick={() => localStorage.setItem("carId", item._id)}
              >
                <VehicleCard item={item} featured={true} key={item.name} />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* eg.browse used cars */}
      <div className=" max-w-[1400px] mx-auto my-16 px-4">
        <h4 className="font-bold text-3xl my-4">Browse Used</h4>
        <Swiper
          spaceBetween={50}
          slidesPerView={3}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          loop={true}
          breakpoints={{
            200: { slidesPerView: 1 }, // Small screens
            768: { slidesPerView: 2 }, // Medium screens
            1024: { slidesPerView: 3 }, // Large screens
          }}
          autoplay={{
            delay: 1000, // Delay between transitions in milliseconds
            disableOnInteraction: false, // Continue autoplay even after interaction
          }}
          modules={[Autoplay]}
        >
          {vehicles?.map((item) => (
            <SwiperSlide>
              <Link
                to={`/cars/${item._id}`}
                onClick={() => localStorage.setItem("carId", item._id)}
              >
                <VehicleCard item={item} featured={true} key={item.name} />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}

export default VehicleList;
