import React from "react";
import styles from "./VehiclePage.module.css";
const Search = () => {
  return (
    <div className={styles.searchMain}>
      <div className={styles.searchContainer}>
        <div className={styles.select1Main}>
          <select name="" id="" className={styles.searchSelect}>
            <option value="">Marker</option>
            <option value="">option1</option>
          </select>
          <select name="" id="" className={styles.searchSelect}>
            <option value="">Model</option>
            <option value="">option1</option>
          </select>
          <select name="" id="" className={styles.searchSelect}>
            <option value="">Year</option>
            <option value="">option1</option>
          </select>
        </div>
        <br />
        <div className={styles.select1Main}>
          <div className={styles.inputPriceMain}>
            <input type="text" className={styles.priceInput1} />
            <input type="text" className={styles.priceInput2} />
            <button className={styles.btninput}>$</button>
          </div>
          <select name="" id="" className={styles.searchSelect}>
            <option value="">Condition</option>
            <option value="">option1</option>
          </select>
          <select name="" id="" className={styles.searchSelect}>
            <option value="">Type</option>
            <option value="">option1</option>
          </select>
        </div>
        <div className={styles.searchBtnMain}>
          <button className={styles.searchBtn}>Search</button>
        </div>
      </div>
    </div>
  );
};

export default Search;
