import React from "react";
import { MaterialReactTable } from "material-react-table";
import { Button } from "@mui/material";

function DashboardPerformanceTable({ vehicles }) {
  const columns = [
    {
      header: "Vehicle ID",
      accessorKey: "_id", // accessor key for the column data
    },
    {
      header: "views",
    },
    {
      header: "Make",
      accessorKey: "make",
    },
    {
      header: "Model",
      accessorKey: "model",
    },
    {
      header: "Price",
      accessorKey: "price",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
  ];

  const data = [
    {
      id: "Kevin Michael",
      name: "Daihatsu Cuore 2007",
      views: 123,
      detailed: 123,
      enquiries: 123,
    },
    {
      id: "Kevin Michael",
      name: "Daihatsu Cuore 2007",
      views: 23,
      detailed: 23,
      enquiries: 23,
    },
    {
      id: "Kevin Michael",
      name: "Daihatsu Cuore 2007",
      views: 34,
      detailed: 34,
      enquiries: 34,
    },
    {
      id: "Kevin Michael",
      name: "Daihatsu Cuore 2007",
      views: 45,
      detailed: 45,
      enquiries: 45,
    },
  ];

  return (
    <div className="overflow-x-auto mt-16">
      <MaterialReactTable
        columns={columns}
        data={vehicles}
        enableSorting={false} // Disable sorting
        enablePagination={false} // Disable pagination
        enableFiltering={false} // Disable filtering
        muiTableHeadCellProps={{
          sx: {
            backgroundColor: "#1976d2",
            color: "white",
            fontWeight: "bold",
            fontSize: "16px",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            backgroundColor: "#fff",
            color: "#333",
            // padding: "12px 16px",
          },
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: "400px", // Add max height to enable scrolling if needed
          },
        }}
      />
    </div>
  );
}

export default DashboardPerformanceTable;
