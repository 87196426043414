import { useState } from "react";
import { Link } from "react-router-dom";
import daihatsuCuore from "../Images/daihatsu-cuore.png";
import logoDark from "../Images/logo-dark.png";
import card from "../Images/card.png";
import VehicleCard from "./Common/VehicleCard";
import { IoConstructOutline } from "react-icons/io5";
import { Circles } from "react-loader-spinner";
const vehicle = {
  name: "Dihatsu Cuore 2007",
  price: "8,000",
  location: "Harare",
  imgs: [daihatsuCuore, daihatsuCuore, daihatsuCuore],
  year: "2007",
  fuel: "Petrol",
  miles: "14,590km",
  transmission: "Automatic",
  details:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam finibus a justo et blandit. Mauris dapibus velit ut sem sodales, sit amet ullamcorper tortor dignissim. Quisque eu sapien leo. Vestibulum vitae urna vulputate, gravida nisl vitae, mattis justo. Suspendisse nulla leo, venenatis sit amet fermentum et, posuere non quam. Proin lobortis consectetur ante, eu luctus justo porta lacinia. Fusce rhoncus nulla vel vulputate lacinia. Donec a placerat magna, sed maximus nunc. Nullam non aliquam mi, non eleifend enim. Nunc et tempus enim. Sed quis diam tincidunt, tincidunt lectus et, ullamcorper odio.",
};

function DashboardFeature({ vehicles }) {
  const [displayedImage, setDisplayedImage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = function () {
    setShowModal(false);
  };

  const handleSubmit = function (e) {
    e.preventDefault();
    setShowModal(false);
  };

  const handleFeature = async ({ listingId }) => {
    setLoading(true);
    const token = localStorage.token;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/listing/feature/${listingId}`,
        {
          method: "PATCH",
          headers: {
            "x-auth-token": token, // Add token to the header
          },
        }
      );

      if (response.ok) {
        setLoading(false);
        alert("Listing is now featured");
        // window.location.reload(); // Reload the page on success
      } else {
        setLoading(false);
        const errorData = await response.json();
        alert(`Error: ${errorData.message}`);
        console.log(errorData);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error adding listing:", error);
      alert("An error occurred. Please try again.", error);
    }
  };

  return (
    <div>
      {showModal && (
        <div
          className="fixed inset-0 z-10 px-4 flex justify-center items-center bg-black bg-opacity-50"
          onClick={handleClose} // Clicking outside modal closes it
        >
          <div
            className="bg-white rounded-lg p-6 w-full max-w-md relative"
            onClick={(e) => e.stopPropagation()} // Prevent clicking inside modal from closing it
          >
            {/* inside the bidding modal */}
            <div className="flex justify-between items-start">
              <img src={logoDark} alt="" />
              <button
                className="text-zinc-400 hover:text-zinc-500"
                onClick={handleClose}
              >
                &#x2716;
              </button>
            </div>

            <h3 className="font-bold text-2xl my-3">
              Enter Information to Feature
            </h3>

            <form
              onSubmit={handleSubmit}
              className="text-sm flex flex-col gap-3"
            >
              <div className="flex items-center border border-solid border-zinc-600 rounded-md px-2">
                <div className="flex items-center gap-2 w-full">
                  <label htmlFor="card-number">
                    <img src={card} alt="" />
                  </label>
                  <input
                    type="tel"
                    id="card-number"
                    name="card-number"
                    pattern="[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}"
                    required
                    className="w-full py-2 focus:outline-none"
                    placeholder="4242 4242 4242 4242"
                  />
                </div>
                <input
                  type="month"
                  id="month"
                  name="month"
                  min="2018-03"
                  value="2018-05"
                  className=""
                />
              </div>
              <input
                type="text"
                id="name"
                name="name"
                required
                minlength="4"
                maxlength="12"
                className="border border-solid border-zinc-600 rounded-md p-2 focus:outline-none"
                placeholder="NAME ON CARD"
              />
              <input
                type="tel"
                id="card-number"
                name="card-number"
                pattern="[0-9]{4}-[0-9]{7}"
                required
                className="border border-solid border-zinc-600 rounded-md p-2 focus:outline-none"
                placeholder="PHONE NUMBER"
              />
              <input
                type="text"
                id="address"
                name="address"
                required
                className="border border-solid border-zinc-600 rounded-md p-2 focus:outline-none"
                placeholder="ADDRESS"
              />
              <input
                type="number"
                id="postal-code"
                name="postal-code"
                required
                className="border border-solid border-zinc-600 rounded-md p-2 focus:outline-none"
                placeholder="POSTAL CODE"
              />

              <button className="text-white bg-[#E7B04C] font-semibold py-2 px-8 self-center rounded-md text-lg">
                Register & Bid
              </button>
            </form>
          </div>
        </div>
      )}

      <div className="space-y-16">
        {/* <div className="grid grid-cols-2 max-w-[1200px] items-start gap-4">
          <figure className="grid grid-cols-3 items-center gap-x-1 md:gap-x-4 gap-y-4 max-w-[648px]">
            <img
              src={vehicle.imgs[displayedImage]}
              alt=""
              className="col-span-3 row-span-3 rounded-md"
            />

            <img
              src={vehicle.imgs[0]}
              alt=""
              onClick={() => setDisplayedImage(0)}
              className={`${
                displayedImage === 0 ? "border-zinc-300" : " border-white"
              } border-2 border-solid w-52`}
            />
            <img
              src={vehicle.imgs[0]}
              alt=""
              onClick={() => setDisplayedImage(1)}
              className={`${
                displayedImage === 1 ? "border-zinc-300" : " border-white"
              } border-3 border-solid w-52`}
            />
            <img
              src={vehicle.imgs[0]}
              alt=""
              onClick={() => setDisplayedImage(2)}
              className={`${
                displayedImage === 2 ? "border-zinc-300" : " border-white"
              } border-2 border-solid w-52`}
            />
          </figure>

          <div>
            <div className=" flex flex-col gap-16 mt-2">
              <div className="space-y-4">
                <h2 className="font-semibold text-4xl">{vehicle.name}</h2>

                <div className="flex items-center gap-4 w-full md:w-2/3">
                  <label htmlFor="location" className="font-semibold pl-1">
                    Location
                  </label>
                  <select
                    name="location"
                    id="location"
                    className="border border-solid border-zinc-400 rounded-md p-2"
                  >
                    <option value="california">California</option>
                  </select>
                </div>
                <input
                  type="text"
                  id="note"
                  required
                  className="border border-solid border-zinc-400 rounded-md p-2 w-full md:w-2/3"
                  placeholder="Highlight e.g. Halloween etc."
                />
                <p className="text-xl">
                  <span className="font-semibold">Price:</span>&nbsp;$121
                </p>
                <p className="text-xl">
                  <span className="font-semibold">Total:</span>&nbsp;$121
                </p>
              </div>
              <div className="flex gap-4">
                <button
                  onClick={() => setShowModal(true)}
                  className="text-white font-semibold basis-full bg-[#E7B04C] rounded-md py-2 px-4"
                >
                  Continue
                </button>
                <button className="text-white font-semibold basis-full bg-zinc-400 rounded-md py-2 px-4">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div> */}

        <div className="space-y-16">
          <div className="max-w-[1200px]">
            <h2 className="font-semibold text-2xl mb-2">Your Vehicles</h2>
            <p className="mb-2">
              You can feature{" "}
              <span className="text-lg font-bold text-[#E7B04C]">
                {vehicles[0]?.user.featureCount}
              </span>{" "}
              more listings this week
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {vehicles?.map((item) => (
                <div className="xl:max-w-96 shadow-xl shadow-zinc-100 rounded-3xl">
                  <figure className="relative">
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}/${item.pictures[0]}`}
                      alt=""
                      className="h-64 w-full object-cover"
                    />
                    {item?.featured && (
                      <span className="absolute bottom-0 left-0 text-white bg-[#E7B04C] p-1 rounded-tr-sm">
                        FEATURED
                      </span>
                    )}
                  </figure>
                  <div className="p-4 mb-4 space-y-1 sm:space-y-2">
                    <p className="font-semibold text-xs sm:text-xl md:text-2xl lg:text-3xl">
                      {`${item?.make} ${item?.model} ${item?.year}`}
                    </p>
                    <p className="text-green-600 text-xs sm:text-base font-semibold">
                      ${item.price}
                    </p>
                    <p className="text-xs">{item?.user.location}</p>
                    <button
                      disabled={item?.featured}
                      className={`w-full py-2 ${
                        item?.featured ? "bg-gray-500" : "bg-[#E7B04C]"
                      } flex justify-center text-white rounded-md mt-4`}
                      onClick={() => handleFeature({ listingId: item?._id })}
                    >
                      {loading ? (
                        <Circles color="#fff" height="20" width="20" />
                      ) : item?.featured ? (
                        "Featured"
                      ) : (
                        "Feature Your Vehicle"
                      )}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="h-screen content-center self-center text-center justify-center items-center">
        <p className="text-3xl text-[#E7B04C] font-semibold">
          Coming Soon Next Week
        </p>
        <div className="flex justify-center mt-6">
          <IoConstructOutline className="text-6xl text-[#E7B04C]" />
        </div>
      </div> */}
    </div>
  );
}

export default DashboardFeature;
