import { useState, useEffect } from "react";
import calendar from "../Images/calendar.png";
import gear from "../Images/gear.png";
import speedometer from "../Images/speedometer.png";
import fuel from "../Images/fuel.png";
import daihatsuCuore from "../Images/daihatsu-cuore.png";
import logoDark from "../Images/logo-dark.png";
import card from "../Images/card.png";
import VehicleCard from "./Common/VehicleCard";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa";

// can be either buyer or seller conditional rendering on line 71 && line 249
// const user = "buyer";
const user = "seller";

const vehicle = {
  name: "Dihatsu Cuore 2007",
  price: "8,000",
  location: "Harare",
  imgs: [daihatsuCuore, daihatsuCuore, daihatsuCuore],
  year: "2007",
  fuel: "Petrol",
  miles: "14,590km",
  transmission: "Automatic",
  details:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam finibus a justo et blandit. Mauris dapibus velit ut sem sodales, sit amet ullamcorper tortor dignissim. Quisque eu sapien leo. Vestibulum vitae urna vulputate, gravida nisl vitae, mattis justo. Suspendisse nulla leo, venenatis sit amet fermentum et, posuere non quam. Proin lobortis consectetur ante, eu luctus justo porta lacinia. Fusce rhoncus nulla vel vulputate lacinia. Donec a placerat magna, sed maximus nunc. Nullam non aliquam mi, non eleifend enim. Nunc et tempus enim. Sed quis diam tincidunt, tincidunt lectus et, ullamcorper odio.",
};

function DashboardListings({ vehicles }) {
  const navigate = useNavigate();
  console.log("vehicles", vehicles);

  useEffect(() => {}, [vehicles]);

  const [displayedImage, setDisplayedImage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [vehicleIndividual, setVehicleIndividual] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = function () {
    setShowModal(false);
  };

  const handleSubmit = function (e) {
    e.preventDefault();
    setShowModal(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!localStorage.token) {
      navigate("/login");
    }
    if (localStorage.vehicleId) {
      let vehicleIndividualTemp = vehicles.filter((item) =>
        item._id.includes(localStorage.vehicleId)
      );

      setVehicleIndividual(vehicleIndividualTemp[0]);
    }
  }, [localStorage.vehicleId]);
  console.log("vehicleIndividual", vehicleIndividual);

  const handleSold = async ({ listingId }) => {
    console.log("aaaa");
    let token = localStorage.token;

    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/listing/${listingId}/sell`,
        {
          method: "PUT",
          headers: {
            "x-auth-token": token, // Add token to the header
          },
        }
      );

      if (response.ok) {
        setLoading(false);
        alert("Listing is now sold");
        window.location.reload(); // Reload the page on success
      } else {
        setLoading(false);
        const errorData = await response.json();
        alert(`Error: ${errorData.message}`);
        console.log(errorData);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error adding listing:", error);
      alert("An error occurred. Please try again.", error);
    }
  };
  return (
    <div>
      {showModal && (
        <div
          className="fixed inset-0 z-10 px-4 flex justify-center items-center bg-black bg-opacity-50"
          onClick={handleClose} // Clicking outside modal closes it
        >
          <div
            className="bg-white rounded-lg p-6 w-full max-w-md relative"
            onClick={(e) => e.stopPropagation()} // Prevent clicking inside modal from closing it
          >
            {/* inside the bidding modal */}
            <div className="flex justify-between items-start">
              <img src={logoDark} alt="" />
              <button
                className="text-zinc-400 hover:text-zinc-500"
                onClick={handleClose}
              >
                &#x2716;
              </button>
            </div>

            <h3 className="font-bold text-2xl my-3">
              Enter Information to Feature
            </h3>

            <form
              onSubmit={handleSubmit}
              className="text-sm flex flex-col gap-3"
            >
              <div className="flex items-center id border-zinc-600 rounded-md px-2">
                <div className="flex items-center gap-2 w-full">
                  <label htmlFor="card-number">
                    <img src={card} alt="" />
                  </label>
                  <input
                    type="tel"
                    id="card-number"
                    name="card-number"
                    pattern="[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}"
                    required
                    className="w-full py-2 focus:outline-none"
                    placeholder="4242 4242 4242 4242"
                  />
                </div>
                <input
                  type="month"
                  id="month"
                  name="month"
                  min="2018-03"
                  value="2018-05"
                  className=""
                />
              </div>
              <input
                type="text"
                id="name"
                name="name"
                required
                minlength="4"
                maxlength="12"
                className="border border-solid border-zinc-600 rounded-md p-2 focus:outline-none"
                placeholder="NAME ON CARD"
              />
              <input
                type="tel"
                id="card-number"
                name="card-number"
                pattern="[0-9]{4}-[0-9]{7}"
                required
                className="border border-solid border-zinc-600 rounded-md p-2 focus:outline-none"
                placeholder="PHONE NUMBER"
              />
              <input
                type="text"
                id="address"
                name="address"
                required
                className="border border-solid border-zinc-600 rounded-md p-2 focus:outline-none"
                placeholder="ADDRESS"
              />
              <input
                type="number"
                id="postal-code"
                name="postal-code"
                required
                className="border border-solid border-zinc-600 rounded-md p-2 focus:outline-none"
                placeholder="POSTAL CODE"
              />

              <button className="text-white bg-[#E7B04C] font-semibold py-2 px-8 self-center rounded-md text-lg">
                Register & Bid
              </button>
            </form>
          </div>
        </div>
      )}

      {vehicleIndividual ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 max-w-[1200px] items-start gap-4">
          <figure className="grid grid-cols-3 items-center gap-x-1 md:gap-x-4 gap-y-4 max-w-[648px]">
            <img
              src={`${
                process.env.REACT_APP_UPLOAD_URL +
                "/" +
                vehicleIndividual?.pictures[displayedImage]
              }`}
              alt=""
              className="col-span-3 rounded-md w-full object-cover md:h-[500px]"
            />
            {vehicleIndividual?.pictures?.map((picture, index) => (
              <img
                key={index}
                src={`${process.env.REACT_APP_UPLOAD_URL}/${picture}`}
                alt={`Picture ${index + 1}`}
                onClick={() => setDisplayedImage(index)} // Update the displayed image based on click
                className="w-full cursor-pointer"
              />
            ))}
          </figure>

          <div>
            <div className="grid py-4 border-2 max-md:px-2 border-solid items-center border-zinc-300 rounded-md">
              <div className="text-[#E7B04C] text-3xl font-semibold px-4">
                ${vehicleIndividual?.price}
              </div>
              <div className="w-0.5 bg-zinc-300 rounded-sm h-full"></div>
              <div className="md:flex gap-x-3 px-2 items-center">
                {vehicleIndividual?.featured && (
                  <div className="text-white bg-[#E7B04C] rounded-md px-4 py-1 my-2 md:ml-2">
                    {vehicleIndividual?.featured ? "Featured" : ""}
                  </div>
                )}
                <div className="text-white bg-green-900 rounded-md px-4 py-1 my-2">
                  {vehicleIndividual?.status === "Pending"
                    ? "Available"
                    : "Sold"}
                </div>
              </div>
              {vehicleIndividual?.user?.role !== "dealership" && (
                <div className="flex px-4 py-2 gap-4">
                  <button className="bg-[#3EB549] p-2 basis-full rounded-md text-white font-semibold flex justify-center items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 h-4 fill-white"
                      viewBox="0 0 512 512"
                    >
                      <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                    </svg>
                    <span>Send Message</span>
                  </button>
                  <button className="bg-[#3E6CB5] p-2 basis-full rounded-md text-white font-semibold flex justify-center items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 h-4 fill-white"
                      viewBox="0 0 512 512"
                    >
                      <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                    </svg>
                    <span>Call</span>
                  </button>
                </div>
              )}
            </div>

            <div className="grid grid-cols-[1fr_1px_1fr_1px_1fr_1px_1fr] border-2 border-solid items-center border-zinc-300 rounded-md py-4 my-4">
              <div className="flex flex-col items-center justify-center">
                <img src={calendar} alt="" className="w-10 h-auto" />
                <p className="text-[#3E6CB5]">{vehicleIndividual?.year}</p>
              </div>

              <div className="w-px bg-zinc-200 rounded-sm h-full"></div>

              <div className="flex flex-col items-center justify-center">
                <img src={fuel} alt="" className="w-10 h-auto" />
                <p className="text-[#3E6CB5]">{vehicleIndividual?.fuel}</p>
              </div>

              <div className="w-px bg-zinc-200 rounded-sm h-full"></div>

              <div className="flex flex-col items-center justify-center">
                <img src={speedometer} alt="" className="w-10 h-auto" />
                <p className="text-[#3E6CB5]">{vehicleIndividual?.mileage}</p>
              </div>

              <div className="w-px bg-zinc-200 rounded-sm h-full"></div>

              <div className="flex flex-col items-center justify-center">
                <img src={gear} alt="" className="w-10 h-auto" />
                <p className="text-[#3E6CB5]">
                  {vehicleIndividual?.transmission}
                </p>
              </div>
            </div>

            <div className="space-y-4 mt-8">
              <h2 className="font-semibold text-4xl">{`${vehicleIndividual?.make} ${vehicleIndividual?.model}`}</h2>
              <p className="font-semibold text-lg">
                Condition:{" "}
                <span className="text-[#E7B04C]">
                  {vehicleIndividual?.condition}
                </span>
              </p>
              <p className="text-lg">{vehicleIndividual?.description}</p>
              <h2 className="font-bold text-lg mt-4">Spec Sheets</h2>
              {vehicleIndividual?.registration.map((item) => (
                // <p>{`${process.env.REACT_APP_UPLOAD_URL}/${item}`}</p>
                <Link
                  to={`${process.env.REACT_APP_UPLOAD_URL}/${item}`}
                  target="_blank"
                >
                  <div className="flex gap-x-6 items-center mt-4">
                    <FaFilePdf className="text-2xl" />

                    <p>View PDF</p>
                  </div>
                </Link>
              ))}
              {user === "seller" && (
                <div className="flex gap-4">
                  <Link to={`/edit-listing/${vehicleIndividual?._id}`}>
                    <button className="text-white basis-full bg-zinc-400 rounded-md py-2 px-12">
                      Edit
                    </button>
                  </Link>
                  <button
                    onClick={() =>
                      handleSold({ listingId: vehicleIndividual?._id })
                    }
                    className="text-white basis-full bg-black rounded-md py-2 px-4"
                  >
                    {vehicleIndividual?.status === "Sold"
                      ? "Vehicle Sold"
                      : "Mark As Sold"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 max-w-[1200px] items-start gap-4">
          <figure className="grid grid-cols-3 items-center gap-x-1 md:gap-x-4 gap-y-4 max-w-[648px]">
            <img
              src={`${
                process.env.REACT_APP_UPLOAD_URL +
                "/" +
                vehicles[vehicles.length - 1]?.pictures[displayedImage]
              }`}
              alt=""
              className="col-span-3 rounded-md w-full object-cover md:h-[500px]"
            />
            {vehicles[vehicles.length - 1]?.pictures?.map((picture, index) => (
              <img
                key={index}
                src={`${process.env.REACT_APP_UPLOAD_URL}/${picture}`}
                alt={`Picture ${index + 1}`}
                onClick={() => setDisplayedImage(index)} // Update the displayed image based on click
                className="w-full cursor-pointer"
              />
            ))}
          </figure>

          <div>
            <div className="grid grid-cols-[auto_1px_1fr] py-4 border-2 border-solid items-center border-zinc-300 rounded-md">
              <div className="text-[#E7B04C] text-3xl font-semibold px-4">
                ${vehicles[vehicles.length - 1]?.price}
              </div>
              <div className="w-0.5 bg-zinc-300 rounded-sm h-full"></div>

              {/* {user === "seller" && (
                <div className="flex px-4 py-2 gap-4">
                  <button className="bg-[#3EB549] p-2 basis-full rounded-md text-white font-semibold flex justify-center items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 h-4 fill-white"
                      viewBox="0 0 512 512"
                    >
                      <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                    </svg>
                    <span>Send Message</span>
                  </button>
                  <button className="bg-[#3E6CB5] p-2 basis-full rounded-md text-white font-semibold flex justify-center items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 h-4 fill-white"
                      viewBox="0 0 512 512"
                    >
                      <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                    </svg>
                    <span>Call</span>
                  </button>
                </div>
              )} */}
            </div>

            <div className="grid grid-cols-[1fr_1px_1fr_1px_1fr_1px_1fr] border-2 border-solid items-center border-zinc-300 rounded-md py-4 my-4">
              <div className="flex flex-col items-center justify-center">
                <img src={calendar} alt="" className="w-10 h-auto" />
                <p className="text-[#3E6CB5]">
                  {vehicles[vehicles.length - 1]?.year}
                </p>
              </div>

              <div className="w-px bg-zinc-200 rounded-sm h-full"></div>

              <div className="flex flex-col items-center justify-center">
                <img src={fuel} alt="" className="w-10 h-auto" />
                <p className="text-[#3E6CB5]">
                  {vehicles[vehicles.length - 1]?.fuel}
                </p>
              </div>

              <div className="w-px bg-zinc-200 rounded-sm h-full"></div>

              <div className="flex flex-col items-center justify-center">
                <img src={speedometer} alt="" className="w-10 h-auto" />
                <p className="text-[#3E6CB5]">
                  {vehicles[vehicles.length - 1]?.mileage}
                </p>
              </div>

              <div className="w-px bg-zinc-200 rounded-sm h-full"></div>

              <div className="flex flex-col items-center justify-center">
                <img src={gear} alt="" className="w-10 h-auto" />
                <p className="text-[#3E6CB5]">
                  {vehicles[vehicles.length - 1]?.transmission}
                </p>
              </div>
            </div>

            <div className="space-y-4 mt-8">
              <h2 className="font-semibold text-4xl">{`${
                vehicles[vehicles.length - 1]?.make
              } ${vehicles[vehicles.length - 1]?.model}`}</h2>
              <p className="font-semibold text-lg">
                Condition:{" "}
                <span className="text-[#E7B04C]">
                  {vehicles[vehicles.length - 1]?.condition}
                </span>
              </p>
              <p className="text-lg">
                {vehicles[vehicles.length - 1]?.description}
              </p>
              {vehicles[vehicles.length - 1]?.registration.map((item) => (
                // <p>{`${process.env.REACT_APP_UPLOAD_URL}/${item}`}</p>
                <Link
                  to={`${process.env.REACT_APP_UPLOAD_URL}/${item}`}
                  target="_blank"
                >
                  <div className="flex gap-x-6 items-center mt-4">
                    <FaFilePdf className="text-2xl" />

                    <p>View PDF</p>
                  </div>
                </Link>
              ))}

              {user === "seller" && (
                <div className="flex gap-4 ">
                  <Link
                    to={`/edit-listing/${vehicles[vehicles.length - 1]?._id}`}
                  >
                    <button className="text-white basis-full bg-zinc-400 rounded-md py-2 px-4">
                      Edit
                    </button>
                  </Link>
                  {vehicles[vehicles.length - 1]?.status !== "Sold" ? (
                    <button
                      onClick={() =>
                        handleSold({
                          listingId: vehicles[vehicles.length - 1]?._id,
                        })
                      }
                      className="text-white basis-full bg-black rounded-md py-2 px-4"
                    >
                      Mark As Sold
                    </button>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="space-y-16">
        <div className="max-w-[1200px]">
          <h2 className="font-semibold text-2xl mb-4">Listed Vehicles</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {vehicles?.map((item) => (
              <Link
                to="/dashboard"
                onClick={() => localStorage.setItem("vehicleId", item._id)}
              >
                <VehicleCard item={item} key={item.name} />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardListings;
