function VehicleCard({ item, featured }) {
  console.log(item?.featured);
  return (
    <div className="xl:max-w-96 shadow-xl shadow-zinc-100 rounded-3xl">
      <figure className="relative">
        <img
          src={`${process.env.REACT_APP_UPLOAD_URL}/${item.pictures[0]}`}
          alt=""
          className="h-64 w-full object-cover"
        />
        {item?.featured && (
          <span className="absolute bottom-0 left-0 text-white bg-[#E7B04C] p-1 rounded-tr-sm">
            FEATURED
          </span>
        )}
      </figure>
      <div className="p-4 mb-4 space-y-1 sm:space-y-2">
        <p className="font-semibold text-xl sm:text-xl md:text-2xl lg:text-3xl">
          {`${item?.make} ${item?.model} ${item?.year}`}
        </p>
        <p className="text-green-600 text-xs sm:text-base font-semibold">
          ${item.price}
        </p>
        <p className="text-xs sm:text-base">{item.location}</p>
      </div>
    </div>
  );
}

export default VehicleCard;
