import { useState } from "react";
import { useNavigate } from "react-router-dom";
function Login() {
  const navigate = useNavigate();

  const [userType, setUserType] = useState("seller");
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = function (e) {
    e.preventDefault();

    fetch(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          // Store token in localStorage
          localStorage.setItem("token", result.token);
          localStorage.setItem("user", JSON.stringify(result));
          alert("login successful!");
          if (result.user.role === "dealership") {
            navigate("/dashboard");
          }

          // Optionally redirect user or clear form
          // e.g., navigate("/dashboard");
        } else {
          alert(result.message || "login failed!");
        }
      })
      .catch((error) => {
        console.error("Error during signup:", error);
        alert(error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(data);
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="py-32 px-4">
      <div className="flex flex-col items-center gap-4 mb-4">
        <h2 className="font-bold text-4xl">Login As</h2>
        <div className="flex gap-3">
          <button
            onClick={() => setUserType("seller")}
            className={`${
              userType === "seller"
                ? "text-black border-black"
                : "text-zinc-400 border-white"
            } text-xl py-1 border-b-2 border-solid font-semibold hover:text-zinc-700`}
          >
            Seller
          </button>
          <button
            onClick={() => setUserType("buyer")}
            className={`${
              userType === "buyer"
                ? "text-black border-black"
                : "text-zinc-400 border-white"
            } text-xl py-1 border-b-2 border-solid font-semibold hover:text-zinc-700`}
          >
            Buyer
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="max-w-xl mx-auto space-y-4">
        <div className="flex flex-col gap-1">
          <label htmlFor="email" className="font-semibold text-xl pl-1">
            Email
          </label>
          <input
            onChange={(e) => handleInputChange(e)}
            type="text"
            name="email"
            id="email"
            pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
            required
            className="border border-solid border-zinc-400 rounded-lg p-2"
          />
        </div>

        <div className="flex flex-col gap-1">
          <label htmlFor="password" className="font-semibold text-xl pl-1">
            Password
          </label>
          <input
            onChange={(e) => handleInputChange(e)}
            type="password"
            name="password"
            id="password"
            required
            className="border border-solid border-zinc-400 rounded-lg p-2"
          />
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-1">
              <input
                type="checkbox"
                id="stay-signed-in"
                name="stay-signed-in"
              />
              <label for="stay-signed-in">Keep me signed in</label>
            </div>
            <button className="border-b border-solid border-zinc-700">
              Forgot Password?
            </button>
          </div>
          <button
            className="bg-[#b98d35] text-white font-semibold py-2 rounded-md mt-6"
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>

        <div className="flex items-center">
          <div className="h-0.5 bg-zinc-300 w-full"></div>
          <span className="text-zinc-500 px-1">or</span>
          <div className="h-0.5 bg-zinc-300 w-full"></div>
        </div>

        <div className="flex flex-col gap-2">
          <button className="flex items-center justify-center gap-4 border border-solid border-zinc-500 rounded-lg py-2 px-8 w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-auto fill-orange-500"
              viewBox="0 0 488 512"
            >
              <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
            </svg>
            <span className="text-xl">Continue with Google</span>
          </button>
          <button className="flex items-center justify-center gap-4 border border-solid border-zinc-500 rounded-lg py-2 px-8 w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-auto"
              viewBox="0 0 384 512"
            >
              <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
            </svg>
            <span className="text-xl">Continue with Apple</span>
          </button>
          <button className="flex items-center justify-center gap-4 border border-solid border-zinc-500 rounded-lg py-2 px-8 w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-auto fill-blue-500"
              viewBox="0 0 512 512"
            >
              <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" />
            </svg>
            <span className="text-xl">Continue with Facebook</span>
          </button>
        </div>
      </form>
    </div>
  );
}

export default Login;
