import { useState, useEffect } from "react";
import VehicleCard from "../../Components/Common/VehicleCard";
import calendar from "../../Images/calendar.png";
import gear from "../../Images/gear.png";
import speedometer from "../../Images/speedometer.png";
import fuel from "../../Images/fuel.png";
import logoDark from "../../Images/logo-dark.png";
import card from "../../Images/card.png";
import axios from "axios";
import { Audio } from "react-loader-spinner";
import daihatsuCuore from "../../Images/daihatsu-cuore.png";
import { Link, useNavigate } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io5";
import { FaFilePdf } from "react-icons/fa";
// can be either buyer or seller conditional rendering on line 71 && line 249
// const user = "buyer";
const user = "buyer";

const vehicle = {
  name: "Dihatsu Cuore 2007",
  price: "8,000",
  location: "Harare",
  imgs: [daihatsuCuore, daihatsuCuore, daihatsuCuore],
  year: "2007",
  fuel: "Petrol",
  miles: "14,590km",
  transmission: "Automatic",
  details:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam finibus a justo et blandit. Mauris dapibus velit ut sem sodales, sit amet ullamcorper tortor dignissim. Quisque eu sapien leo. Vestibulum vitae urna vulputate, gravida nisl vitae, mattis justo. Suspendisse nulla leo, venenatis sit amet fermentum et, posuere non quam. Proin lobortis consectetur ante, eu luctus justo porta lacinia. Fusce rhoncus nulla vel vulputate lacinia. Donec a placerat magna, sed maximus nunc. Nullam non aliquam mi, non eleifend enim. Nunc et tempus enim. Sed quis diam tincidunt, tincidunt lectus et, ullamcorper odio.",
};

function InfoPage({ item }) {
  const navigate = useNavigate();
  const [displayedImage, setDisplayedImage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    console.log(item);
    fetchListing();
  }, []);

  const fetchListing = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }/listing/get-listing/${localStorage.getItem("carId")}`
      );
      setListing(response?.data?.listing);
      console.log("listing", response?.data.listing);
    } catch (err) {
      setError("Failed to fetch listings.");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = function () {
    setShowModal(false);
  };

  const handleSubmit = function (e) {
    e.preventDefault();
    setShowModal(false);
  };

  const handleBuyer = () => {
    if (!localStorage.token) {
      alert("You need to sign up as buyer first");
      navigate("/signup");
      localStorage.setItem("status", "buyer");
    } else {
      return;
    }
  };

  return (
    <div>
      {/* {showModal && (
        <div
          className="fixed inset-0 z-10 px-4 flex justify-center items-center bg-black bg-opacity-50"
          onClick={handleClose}
        >
          <div
            className="bg-white rounded-lg p-6 w-full max-w-md relative"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-start">
              <img src={logoDark} alt="" />
              <button
                className="text-zinc-400 hover:text-zinc-500"
                onClick={handleClose}
              >
                &#x2716;
              </button>
            </div>

            <h3 className="font-bold text-2xl my-3">
              Enter Information to Bid
            </h3>

            <form
              onSubmit={handleSubmit}
              className="text-sm flex flex-col gap-3"
            >
              <div className="flex items-center border border-solid border-zinc-600 rounded-md px-2">
                <div className="flex items-center gap-2 w-full">
                  <label htmlFor="card-number">
                    <img src={card} alt="" />
                  </label>
                  <input
                    type="tel"
                    id="card-number"
                    name="card-number"
                    pattern="[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}"
                    required
                    className="w-full py-2 focus:outline-none"
                    placeholder="4242 4242 4242 4242"
                  />
                </div>
                <input
                  type="month"
                  id="month"
                  name="month"
                  min="2018-03"
                  value="2018-05"
                  className=""
                />
              </div>
              <input
                type="text"
                id="name"
                name="name"
                required
                minlength="4"
                maxlength="12"
                className="border border-solid border-zinc-600 rounded-md p-2 focus:outline-none"
                placeholder="NAME ON CARD"
              />
              <input
                type="tel"
                id="card-number"
                name="card-number"
                pattern="[0-9]{4}-[0-9]{7}"
                required
                className="border border-solid border-zinc-600 rounded-md p-2 focus:outline-none"
                placeholder="PHONE NUMBER"
              />
              <input
                type="text"
                id="address"
                name="address"
                required
                className="border border-solid border-zinc-600 rounded-md p-2 focus:outline-none"
                placeholder="ADDRESS"
              />
              <input
                type="number"
                id="postal-code"
                name="postal-code"
                required
                className="border border-solid border-zinc-600 rounded-md p-2 focus:outline-none"
                placeholder="POSTAL CODE"
              />

              <button className="text-white bg-[#E7B04C] font-semibold py-2 px-8 self-center rounded-md text-lg">
                Register & Bid
              </button>
            </form>
          </div>
        </div>
      )} */}
      {loading ? (
        <div className="flex items-center h-screen justify-center">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="rgb(185, 142, 53)"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 max-w-[1400px] items-start mx-auto my-16 px-4 gap-4">
            <figure className="grid grid-cols-3 items-center gap-x-1 md:gap-x-4 gap-y-4 max-w-[648px]">
              <img
                src={`${process.env.REACT_APP_UPLOAD_URL}/${listing?.pictures[displayedImage]}`}
                alt=""
                className="col-span-3 rounded-md w-full object-cover md:h-[500px]"
              />
              {listing?.pictures?.map((picture, index) => (
                <img
                  key={index}
                  src={`${process.env.REACT_APP_UPLOAD_URL}/${picture}`}
                  alt={`Picture ${index + 1}`}
                  onClick={() => setDisplayedImage(index)} // Update the displayed image based on click
                  className="w-full cursor-pointer"
                />
              ))}
            </figure>

            <div>
              <div className="grid grid-cols-[auto_1px_1fr] py-4 border-2 border-solid items-center border-zinc-300 rounded-md">
                <div className="text-[#E7B04C] text-4xl font-semibold px-1 md:px-4">
                  ${listing?.price}
                </div>
                <div className="w-0.5 bg-zinc-300 rounded-sm h-full"></div>

                {user === "buyer" && (
                  <div className="flex-col md:flex px-4 py-2 gap-4">
                    {localStorage.getItem("status") === "buyer" ? (
                      <>
                        <Link
                          to={`https://wa.me/${listing?.user?.phoneNumber}`}
                          target="_blank"
                        >
                          <button className="max-md:my-4 bg-[#3EB549] p-2 basis-full rounded-md text-white font-semibold flex justify-center items-center gap-2">
                            <IoLogoWhatsapp className="text-xl" />
                            <span>Send Message</span>
                          </button>
                        </Link>
                        <Link
                          to={`tel:${listing?.user?.phoneNumber}`}
                          target="blank"
                        >
                          <button className="bg-[#3E6CB5] px-10 py-2 basis-full rounded-md text-white font-semibold flex justify-center items-center gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-4 fill-white"
                              viewBox="0 0 512 512"
                            >
                              <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                            </svg>
                            <span>Call</span>
                          </button>
                        </Link>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => handleBuyer()}
                          className="max-md:my-4 bg-[#3EB549] p-2 basis-full rounded-md text-white font-semibold flex justify-center items-center gap-2"
                        >
                          <IoLogoWhatsapp className="text-xl" />
                          <span>Send Message</span>
                        </button>
                        <button className="bg-[#3E6CB5] px-10 py-2 basis-full rounded-md text-white font-semibold flex justify-center items-center gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4 fill-white"
                            viewBox="0 0 512 512"
                          >
                            <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                          </svg>
                          <span>Call</span>
                        </button>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="grid grid-cols-[1fr_1px_1fr_1px_1fr_1px_1fr] border-2 border-solid items-center border-zinc-300 rounded-md py-4 my-4">
                <div className="flex flex-col items-center justify-center">
                  <img src={calendar} alt="" className="w-10 h-auto" />
                  <p className="text-[#3E6CB5]">{listing?.year}</p>
                </div>

                <div className="w-px bg-zinc-200 rounded-sm h-full"></div>

                <div className="flex flex-col items-center justify-center">
                  <img src={fuel} alt="" className="w-10 h-auto" />
                  <p className="text-[#3E6CB5]">{listing?.fuel}</p>
                </div>

                <div className="w-px bg-zinc-200 rounded-sm h-full"></div>

                <div className="flex flex-col items-center justify-center">
                  <img src={speedometer} alt="" className="w-10 h-auto" />
                  <p className="text-[#3E6CB5]">{listing?.mileage}</p>
                </div>

                <div className="w-px bg-zinc-200 rounded-sm h-full"></div>

                <div className="flex flex-col items-center justify-center">
                  <img src={gear} alt="" className="w-10 h-auto" />
                  <p className="text-[#3E6CB5]">{listing?.transmission}</p>
                </div>
              </div>

              <div className="space-y-4 mt-16">
                <h2 className="font-semibold text-4xl">
                  {listing?.make} {listing?.model} {listing?.year}
                </h2>
                <p className="text-lg">{listing?.description}</p>
                <h2 className="font-bold text-lg mt-4">Spec Sheets</h2>
                {listing?.registration.map((item) => (
                  // <p>{`${process.env.REACT_APP_UPLOAD_URL}/${item}`}</p>
                  <Link
                    to={`${process.env.REACT_APP_UPLOAD_URL}/${item}`}
                    target="_blank"
                  >
                    <div className="flex gap-x-6 items-center mt-4">
                      <FaFilePdf className="text-2xl" />

                      <p>View PDF</p>
                    </div>
                  </Link>
                ))}
                {user === "seller" && (
                  <div className="flex gap-4 ">
                    <button className="text-white basis-full bg-zinc-400 rounded-md py-2 px-4">
                      Edit
                    </button>
                    <button className="text-white basis-full bg-black rounded-md py-2 px-4">
                      Sold
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default InfoPage;
