import suzuki from "../../Images/suzuki.png";
import toyota from "../../Images/toyota.png";
import honda from "../../Images/honda.png";
import kia from "../../Images/kia.png";
import hyundai from "../../Images/hyundai.png";
import mg from "../../Images/mg.png";
import changan from "../../Images/changan.png";
import byd from "../../Images/byd.png";
import bmw from "../../Images/bmw.png";
import audi from "../../Images/audi.png";
import proton from "../../Images/proton.png";
import united from "../../Images/united.png";

function Sponsored() {
  const partners = [
    { name: "Suzuki", logo: suzuki },
    { name: "Toyota", logo: toyota },
    { name: "Honda", logo: honda },
    { name: "Kia", logo: kia },
    { name: "Hyundai", logo: hyundai },
    { name: "MG", logo: mg },
    { name: "Changan", logo: changan },
    { name: "BYD", logo: byd },
    { name: "BMW", logo: bmw },
    { name: "Audi", logo: audi },
    { name: "Proton", logo: proton },
    { name: "United", logo: united },
  ];

  return (
    <div className="bg-[#F8F8F8] pb-16 -mb-12 px-4">
      <h2 className="text-center text-[40px] font-bold py-4">
        Our Sponsored Partners
      </h2>
      <div className="grid grid-cols-3 sm:grid-cols-4 xl:grid-cols-6 max-w-7xl gap-8 mx-auto">
        {partners.map((partner) => (
          <figure className="rounded-lg bg-white flex flex-col items-center justify-center xl:w-44">
            <img src={partner.logo} alt={partner.name} />
            <p>{partner.name}</p>
          </figure>
        ))}
      </div>
    </div>
  );
}

export default Sponsored;
