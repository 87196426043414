import redCar from "../../Images/car.png";

function Form({ children }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div>{children}</div>

      {/* <div className="bg-[url(./Images/car.png)] bg-no-repeat min-h-screen hidden md:block"></div> */}
      <img
        src={redCar}
        alt=""
        className="hidden md:block h-screen right-0 w-1/2 object-cover fixed"
      />
    </div>
  );
}

export default Form;
