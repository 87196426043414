import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import styles from "./VehiclePage.module.css";
import Search from "./Search";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import SliderImg1 from "../../Images/sliderImg1.png";
import SliderImg2 from "../../Images/sliderImg2.png";
import SliderImg3 from "../../Images/sliderImg3.png";
import Sponsored from "../../Components/Common/Sponsored";
import Bulldozer from "../../Components/Common/Bulldozer";
import CallToAction from "../../Components/Common/CallToAction";
import VehicleList from "../../Components/Common/VehicleList";
import DealersList from "../../Components/Common/DealersList";
import { Audio } from "react-loader-spinner";
import { IoConstructOutline } from "react-icons/io5";

const VehiclePage = ({ bg, dealers }) => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    arrows: false, // Hides the arrows
    responsive: [
      {
        breakpoint: 1370,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 940,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var settings2 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    initialSlide: 0,
    arrows: false, // Hides the arrows
    responsive: [
      {
        breakpoint: 1370,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 940,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    console.log("length", vehicles?.length);
    console.log("working");
    fetchAllVehicles();
  }, []);

  const fetchAllVehicles = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/listing/get-all-listings`
      );
      setVehicles(response?.data?.listings);
      console.log(response?.data.listings);
    } catch (err) {
      setError("Failed to fetch listings.");
    } finally {
      console.log("finally", vehicles);
      setLoading(false);
    }
  };
  return loading ? (
    <div className="flex items-center h-screen justify-center">
      <Audio
        height="80"
        width="80"
        radius="9"
        color="rgb(185, 142, 53)"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
      />
    </div>
  ) : (
    <div>
      <div className={styles.carsMain}>
        <div className={`${bg} bg-center bg-cover bg-no-repeat -mt-48`}>
          <div className={styles.carsMainHeading}>
            <div className={`${styles.carsHeading}`}>
              ZIMBABWE'S PREMIER VEHICLE MARKETPLACE <br /> Revolutionizing How
              Zimbabwe Buys and Sells Vehicles
            </div>
            <div className={styles.carsText}>
              <ul className="mt-3 mb-8 space-y-2">
                <li>
                  <span className="text-green-500">&#10003;</span>
                  &nbsp;Launching a new era of digital automotive trading
                </li>
                <li>
                  <span className="text-green-500">&#10003;</span>
                  &nbsp;AI-powered matching for better deals
                </li>
                <li>
                  <span className="text-green-500">&#10003;</span>
                  &nbsp;Secure, transparent, and professional platform
                </li>
                <li>
                  <span className="text-green-500">&#10003;</span>&nbsp;Simple
                  24/7 Digital Showcase
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Search component */}
        <Search />

        <CallToAction />

        {/* Vehicle List will generate the list of cars or boats or trucks based on data provided as prop */}
        {<VehicleList vehicles={vehicles} />}

        {/* dealers list */}
        {/* {dealers && <DealersList dealers={dealers} />} */}

        {/* Bulldozer component  */}
        <Bulldozer />

        {/* Sponsored Partners component  */}
        <Sponsored />

        {/* Industry component  */}
        <div
          className={styles.feedbackSliderMain}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <Slider {...settings2}>
            <div className={`${styles.indusMain} w-full`}>
              <div className={`${styles.indusContainer} w-full`}>
                <div className={styles.indusHeadingMain}>
                  <div className={styles.indusHeading}>
                    JOIN THE FUTURE OF VEHICLE TRADING
                    <br />
                    Your Success is Our Priority
                  </div>
                  <ul className="mt-3 mb-8 space-y-2 text-white">
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Expert support at every step
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Market leading visibility
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Secure transaction guidance
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Professional listing tools
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Access to verified buyers and premium sellers
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={`${styles.indusMain} w-full`}>
              <div className={`${styles.indusContainer} w-full`}>
                <div className={styles.indusHeadingMain}>
                  <div className={styles.indusHeading}>
                    JOIN THE FUTURE OF VEHICLE TRADING
                    <br />
                    Your Success is Our Priority
                  </div>
                  <ul className="mt-3 mb-8 space-y-2 text-white">
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Expert support at every step
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Market leading visibility
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Secure transaction guidance
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Professional listing tools
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Access to verified buyers and premium sellers
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={`${styles.indusMain} w-full`}>
              <div className={`${styles.indusContainer} w-full`}>
                <div className={styles.indusHeadingMain}>
                  <div className={styles.indusHeading}>
                    JOIN THE FUTURE OF VEHICLE TRADING
                    <br />
                    Your Success is Our Priority
                  </div>
                  <ul className="mt-3 mb-8 space-y-2 text-white">
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Expert support at every step
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Market leading visibility
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Secure transaction guidance
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Professional listing tools
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Access to verified buyers and premium sellers
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Slider>
        </div>

        {/* Feedback  */}
        <div className={styles.feedbackMain}>
          <div className={styles.feedbackHeading}>Our Customers Feedback</div>

          <div className={styles.feedbackSliderMain}>
            <Slider {...settings}>
              <div>
                <div className={styles.sliderContainer}>
                  <div className={styles.imgRateMain}>
                    <img src={SliderImg1} alt="" className={styles.sliderimg} />
                    <div>
                      <Stack spacing={1}>
                        <Rating
                          name="half-rating-read"
                          defaultValue={3.5}
                          precision={0.5}
                          readOnly
                        />
                      </Stack>
                    </div>
                  </div>
                  <div className={styles.SliderHeading}>Floyd Miles</div>
                  <div className={styles.SliderText}>
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet sint. Velit officia consequat duis enim velit
                    mollit. Exercitation veniam consequat sunt nostrud amet.
                    Amet minim mollit non deserunt ullam...
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.sliderContainer}>
                  <div className={styles.imgRateMain}>
                    <img src={SliderImg2} alt="" className={styles.sliderimg} />
                    <div>
                      <Stack spacing={1}>
                        <Rating
                          name="half-rating-read"
                          defaultValue={4}
                          precision={1}
                          readOnly
                        />
                      </Stack>
                    </div>
                  </div>
                  <div className={styles.SliderHeading}>Ronald Richards</div>
                  <div className={styles.SliderText}>
                    ullamco est sit aliqua dolor do amet sint. Velit officia
                    consequat duis enim velit mollit. Exercitation veniam
                    consequat sunt nostrud amet.
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.sliderContainer}>
                  <div className={styles.imgRateMain}>
                    <img src={SliderImg3} alt="" className={styles.sliderimg} />
                    <div>
                      <Stack spacing={1}>
                        <Rating
                          name="half-rating-read"
                          defaultValue={3.5}
                          precision={0.5}
                          readOnly
                        />
                      </Stack>
                    </div>
                  </div>
                  <div className={styles.SliderHeading}>Savannah Nguyen</div>
                  <div className={styles.SliderText}>
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet sint. Velit officia consequat duis enim velit
                    mollit. Exercitation veniam consequat sunt nostrud amet.
                    Amet minim mollit non deserunt ullam...
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.sliderContainer}>
                  <div className={styles.imgRateMain}>
                    <img src={SliderImg1} alt="" className={styles.sliderimg} />
                    <div>
                      <Stack spacing={1}>
                        <Rating
                          name="half-rating-read"
                          defaultValue={3.5}
                          precision={0.5}
                          readOnly
                        />
                      </Stack>
                    </div>
                  </div>
                  <div className={styles.SliderHeading}>Floyd Miles</div>
                  <div className={styles.SliderText}>
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet sint. Velit officia consequat duis enim velit
                    mollit. Exercitation veniam consequat sunt nostrud amet.
                    Amet minim mollit non deserunt ullam...
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.sliderContainer}>
                  <div className={styles.imgRateMain}>
                    <img src={SliderImg2} alt="" className={styles.sliderimg} />
                    <div>
                      <Stack spacing={1}>
                        <Rating
                          name="half-rating-read"
                          defaultValue={4}
                          precision={1}
                          readOnly
                        />
                      </Stack>
                    </div>
                  </div>
                  <div className={styles.SliderHeading}>Ronald Richards</div>
                  <div className={styles.SliderText}>
                    ullamco est sit aliqua dolor do amet sint. Velit officia
                    consequat duis enim velit mollit. Exercitation veniam
                    consequat sunt nostrud amet.
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.sliderContainer}>
                  <div className={styles.imgRateMain}>
                    <img src={SliderImg3} alt="" className={styles.sliderimg} />
                    <div>
                      <Stack spacing={1}>
                        <Rating
                          name="half-rating-read"
                          defaultValue={3.5}
                          precision={0.5}
                          readOnly
                        />
                      </Stack>
                    </div>
                  </div>
                  <div className={styles.SliderHeading}>Savannah Nguyen</div>
                  <div className={styles.SliderText}>
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet sint. Velit officia consequat duis enim velit
                    mollit. Exercitation veniam consequat sunt nostrud amet.
                    Amet minim mollit non deserunt ullam...
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.sliderContainer}>
                  <div className={styles.imgRateMain}>
                    <img src={SliderImg1} alt="" className={styles.sliderimg} />
                    <div>
                      <Stack spacing={1}>
                        <Rating
                          name="half-rating-read"
                          defaultValue={3.5}
                          precision={0.5}
                          readOnly
                        />
                      </Stack>
                    </div>
                  </div>
                  <div className={styles.SliderHeading}>Floyd Miles</div>
                  <div className={styles.SliderText}>
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet sint. Velit officia consequat duis enim velit
                    mollit. Exercitation veniam consequat sunt nostrud amet.
                    Amet minim mollit non deserunt ullam...
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.sliderContainer}>
                  <div className={styles.imgRateMain}>
                    <img src={SliderImg2} alt="" className={styles.sliderimg} />
                    <div>
                      <Stack spacing={1}>
                        <Rating
                          name="half-rating-read"
                          defaultValue={4}
                          precision={1}
                          readOnly
                        />
                      </Stack>
                    </div>
                  </div>
                  <div className={styles.SliderHeading}>Ronald Richards</div>
                  <div className={styles.SliderText}>
                    ullamco est sit aliqua dolor do amet sint. Velit officia
                    consequat duis enim velit mollit. Exercitation veniam
                    consequat sunt nostrud amet.
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.sliderContainer}>
                  <div className={styles.imgRateMain}>
                    <img src={SliderImg3} alt="" className={styles.sliderimg} />
                    <div>
                      <Stack spacing={1}>
                        <Rating
                          name="half-rating-read"
                          defaultValue={3.5}
                          precision={0.5}
                          readOnly
                        />
                      </Stack>
                    </div>
                  </div>
                  <div className={styles.SliderHeading}>Savannah Nguyen</div>
                  <div className={styles.SliderText}>
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet sint. Velit officia consequat duis enim velit
                    mollit. Exercitation veniam consequat sunt nostrud amet.
                    Amet minim mollit non deserunt ullam...
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
    // <div className="h-screen content-center self-center text-center justify-center items-center">
    //   <p className="text-3xl text-[#E7B04C] font-semibold">
    //     Coming Soon Next Week
    //   </p>
    //   <div className="flex justify-center mt-6">
    //     <IoConstructOutline className="text-6xl text-[#E7B04C]" />
    //   </div>
    // </div>
  );
};

export default VehiclePage;
