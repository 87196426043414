import styles from "../../Pages/VehiclePage/VehiclePage.module.css";

function Bulldozer() {
  return (
    <div className="bg-[url(./Images/bulldozer.png)] bg-center bg-cover bg-no-repeat">
      <div className="lg:w-1/2 text-white lg:pl-24 pt-36 pb-36 px-8">
        <div className={styles.carsHeading}>
          TRANSFORMING VEHICLE TRADING IN ZIMBABWE <br /> Where Innovation Meets
          Automotive Excellence
        </div>
        <ul className="mt-3 mb-8 space-y-2">
          <li>
            <span className="text-green-500">&#10003;</span>
            &nbsp;First AI-enhanced automotive marketplace
          </li>
          <li>
            <span className="text-green-500">&#10003;</span>
            &nbsp;Comprehensive vehicle categories from cars to earth movers
          </li>
          <li>
            <span className="text-green-500">&#10003;</span>
            &nbsp;Smart solutions for modern buyers and sellers
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Bulldozer;
