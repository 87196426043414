import React from "react";
import Logo from "../../Images/Logo.png";
import WatsappIcn from "../../Images/watsapp.png";
import TeleIcn from "../../Images/telegram.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="p-16 bg-black text-white">
      <div className="flex justify-between flex-wrap gap-4">
        <div className="flex flex-col gap-2 items-start">
          <img src={Logo} alt="" />
          <div>+1 999 888-76-54</div>
          <div>hello@logoipsum.com</div>
          <div className="flex">
            <img src={WatsappIcn} alt="" />
            <img src={TeleIcn} alt="" />
          </div>
        </div>
        <div className="flex-col flex text-zinc-300">
          <div className="font-semibold text-xl mb-2 text-white">Category</div>
          <Link to="#">Cars</Link>
          <Link to="#">Bikes</Link>
          <Link to="#">Boats</Link>
          <Link to="#">Trucks</Link>
          <Link to="#">Agriculture</Link>
          <Link to="#">Earth Moving</Link>
          <Link to="#">New</Link>
          <Link to="#">Used</Link>
          <Link to="#">Car Rentals</Link>
          <Link to="#">Dealers</Link>
        </div>
        <div className="flex-col flex text-zinc-300">
          <div className="font-semibold text-xl mb-2 text-white">Info</div>
          <Link to="#">About us</Link>
          <Link to="#">Articles</Link>
          <Link to="#">Contacts</Link>
        </div>
        <div className="text-zinc-300">
          <div>2972 Westheimer Rd. Santa Ana Illinois 85486</div>
          <br />
          <div>From 10 a.m. to 6 p.m.</div>
          <div className="text-zinc-600">All days</div>
        </div>

        <div className="border-solid border border-white flex flex-col gap-8 justify-between p-8 rounded-3xl">
          <div className="text-3xl">Subscribe to our newsletter</div>
          <div>
            <div className="group flex py-1">
              <input
                type="email"
                placeholder="your@email.ru"
                className="peer bg-transparent w-full py-1 focus:outline-none text-white border-b-4 border-solid border-zinc-600 focus:border-zinc-400"
              />
              <button className="text-yellow-600 border-b-4 border-solid border-zinc-600 peer-focus:border-zinc-400">
                &#10140;
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between text-xs mt-16 sm:text-sm">
        <div>Copyright@2024</div>
        <div>Privacy</div>
      </div>
    </div>
  );
};

export default Footer;
