import { Link } from "react-router-dom";

function CallToAction() {
  return (
    <div className="my-16 px-4">
      <div className="flex justify-center">
        <h2 className="text-center text-[#E7B04C] font-bold text-3xl translate-y-4 bg-white inline px-1">
          Sell Your Car on BuySellCar and Get the Best Price
        </h2>
      </div>

      <div className="grid-cols-[1fr_1px_1fr] gap-4 items-stretch grid py-12 px-4 border border-solid border-zinc-300 max-w-5xl mx-auto rounded-xl">
        <div className="justify-self-start">
          <h3 className="font-semibold text-2xl">Sell Your Car Smarter</h3>
          <ul className="mt-3 mb-8 space-y-2">
            <li>
              <span className="text-green-500">&#10003;</span>&nbsp;Zimbabwe's
              Premier Auto Marketplace
            </li>
            <li>
              <span className="text-green-500">&#10003;</span>&nbsp; AI-Powered
              Buyer Matching
            </li>
            <li>
              <span className="text-green-500">&#10003;</span>&nbsp;Instant
              Exposure to Quality Buyers
            </li>
            <li>
              <span className="text-green-500">&#10003;</span>&nbsp;Simple
              Simple 3-Step Listing Process
            </li>
            <li>
              <span className="text-green-500">&#10003;</span>&nbsp;Simple 24/7
              Digital Showcase
            </li>
          </ul>
          <Link to="/signup">
            <button className="bg-[#E7B04C] text-white font-semibold py-2 px-4 rounded-md">
              Post your Ad
            </button>
          </Link>
        </div>

        <div className="flex flex-col items-center">
          <div className="w-[1px] h-full bg-zinc-400"></div>
          <div className="text-zinc-500">OR</div>
          <div className="w-[1px] h-full bg-zinc-400"></div>
        </div>

        <div className="justify-self-end">
          <h3 className="font-semibold text-2xl">Elevate Your Dealership</h3>
          <ul className="mt-3 mb-8 space-y-2">
            <li>
              <span className="text-green-500">&#10003;</span>
              &nbsp;First-To-Market Premium Platform
            </li>
            <li>
              <span className="text-green-500">&#10003;</span>&nbsp;AI-Enhanced
              Lead Generation
            </li>
            <li>
              <span className="text-green-500">&#10003;</span>&nbsp;Unlimited
              Premium Listings
            </li>
            <li>
              <span className="text-green-500">&#10003;</span>&nbsp;Advanced
              Analytics Dashboard
            </li>
            <li>
              <span className="text-green-500">&#10003;</span>&nbsp;Priority
              Support & Consultation
            </li>
            <li>
              <span className="text-green-500">&#10003;</span>&nbsp;Verified
              Buyer Network
            </li>
            <li>
              <span className="text-green-500">&#10003;</span>&nbsp;Custom
              Marketing Solutions, & Professional Marketing Tools.
            </li>
          </ul>
          <Link to="/signup">
            <button className="bg-[#E7B04C] text-white font-semibold py-2 px-4 rounded-md">
              Sell Your Vehicle
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CallToAction;
