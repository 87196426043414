import { useState, useEffect } from "react";
import DashboardAddListing from "../../Components/DashboardAddListing";
import DashboardListings from "../../Components/DashboardListings";
import DashboardFeature from "../../Components/DashboardFeature";
import DashboardPerformance from "../../Components/DashboardPerformance";
import axios from "axios";
import { Audio } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
function DashBoard() {
  const navigate = useNavigate();
  // listings, new-listing, feature, performance
  const [tab, setTab] = useState("listings");
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (tab === "listings") {
      console.log("length", vehicles?.length);
      console.log("working");
      fetchUserListings();
    }

    if (!localStorage.token) {
      navigate("/login");
    }

    let obj = JSON.parse(localStorage.getItem("user"));

    // if (obj === null) {
    //   navigate("/login");
    //   return;
    // }
    // if (obj?.user?.subscriptionType == "") {
    //   navigate("/subscriptions");
    //   return;
    // }
    if (obj?.user?.category === "individual") {
      return;
    }
  }, [tab]);
  console.log("vehicles", vehicles);
  const fetchUserListings = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/listing/get-all-listings-logged-in`,
        {
          headers: {
            "x-auth-token": `${localStorage.getItem("token")}`, // Include JWT token
          },
        }
      );
      setVehicles(response?.data?.data);
      if (response?.data?.data.length === 0) {
        setTab("new-listing");
      }
    } catch (err) {
      setError("Failed to fetch listings.");
    } finally {
      console.log("finally", vehicles);
      setLoading(false);
    }
  };

  return loading ? (
    <div className="h-screen flex items-center justify-center">
      <Audio
        height="80"
        width="80"
        radius="9"
        color="rgb(185, 142, 53)"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
      />
    </div>
  ) : (
    <div className="md:grid md:grid-cols-[1fr_2fr] xl:grid-cols-[1fr_4fr]">
      <div className="bg-[#F5F5F5C7] md:flex md:min-h-[80dvh] md:m-6 justify-center px-4 py-16">
        <div className=" flex flex-col items-start gap-12">
          <button
            className="flex items-center gap-3"
            onClick={() => setTab("listings")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                tab === "listings" ? "fill-[#E7B04C]" : "fill-zinc-700"
              } h-6 w-6`}
              viewBox="0 0 512 512"
            >
              <path d="M40 48C26.7 48 16 58.7 16 72l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24L40 48zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L192 64zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zM16 232l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0z" />
            </svg>
            <span
              className={`${
                tab === "listings" ? "text-[#E7B04C]" : "text-zinc-700"
              }`}
            >
              Your Listing
            </span>
          </button>
          <button
            className="flex items-center gap-3"
            onClick={() => setTab("new-listing")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                tab === "new-listing" ? "fill-[#E7B04C]" : "fill-zinc-700"
              } h-6 w-6`}
              viewBox="0 0 448 512"
            >
              <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" />
            </svg>
            <span
              className={`${
                tab === "new-listing" ? "text-[#E7B04C]" : "text-zinc-700"
              }`}
            >
              Add new listing
            </span>
          </button>
          <button
            className="flex items-center gap-3"
            onClick={() => setTab("feature")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                tab === "feature" ? "fill-[#E7B04C]" : "fill-zinc-700"
              } h-6 w-6`}
              viewBox="0 0 512 512"
            >
              <path d="M352 320c88.4 0 160-71.6 160-160c0-15.3-2.2-30.1-6.2-44.2c-3.1-10.8-16.4-13.2-24.3-5.3l-76.8 76.8c-3 3-7.1 4.7-11.3 4.7L336 192c-8.8 0-16-7.2-16-16l0-57.4c0-4.2 1.7-8.3 4.7-11.3l76.8-76.8c7.9-7.9 5.4-21.2-5.3-24.3C382.1 2.2 367.3 0 352 0C263.6 0 192 71.6 192 160c0 19.1 3.4 37.5 9.5 54.5L19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L297.5 310.5c17 6.2 35.4 9.5 54.5 9.5zM80 408a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
            </svg>
            <span
              className={`${
                tab === "feature" ? "text-[#E7B04C]" : "text-zinc-700"
              }`}
            >
              Feature
            </span>
          </button>
          <button
            className="flex items-center gap-3"
            onClick={() => setTab("performance")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                tab === "performance" ? "fill-[#E7B04C]" : "fill-zinc-700"
              } h-6 w-6`}
              viewBox="0 0 512 512"
            >
              <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64L0 400c0 44.2 35.8 80 80 80l400 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L80 416c-8.8 0-16-7.2-16-16L64 64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z" />
            </svg>
            <span
              className={`${
                tab === "performance" ? "text-[#E7B04C]" : "text-zinc-700"
              }`}
            >
              Performance
            </span>
          </button>
        </div>
      </div>
      <div className="p-6">
        {tab === "new-listing" && <DashboardAddListing vehicles={vehicles} />}
        {tab === "listings" && <DashboardListings vehicles={vehicles} />}
        {tab === "feature" && <DashboardFeature vehicles={vehicles} />}
        {tab === "performance" && <DashboardPerformance vehicles={vehicles} />}
      </div>
    </div>
  );
}

export default DashBoard;
