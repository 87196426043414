import Header from "./Header/Header";
import Footer from "./Footer/Footer";

function Layout({ children, dark }) {
  return (
    <>
      <Header dark={dark} />
      {children}
      <Footer />
    </>
  );
}

export default Layout;
