import React from "react";
import styles from "./Header.module.css";
import "./Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../../Images/Logo.png";

const Header = ({ dark }) => {
  return (
    <div className={dark ? "bg-black" : ""}>
      <>
        {["lg"].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
            className="bg-body-tertiary mb-3 navbar-dark"
          >
            <Container fluid>
              <Navbar.Brand href="/">
                <img src={Logo} alt="" className={styles.logoImg} />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <img src={Logo} alt="" className={styles.logoImg} />
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link href="/">Cars</Nav.Link>
                    <Nav.Link href="/">Boats</Nav.Link>
                    <Nav.Link href="/">Bikes</Nav.Link>
                    <Nav.Link href="/">Trucks</Nav.Link>
                    <Nav.Link href="/">Agriculture</Nav.Link>
                    <Nav.Link href="">Earth Moving</Nav.Link>
                    <Nav.Link href="/">New</Nav.Link>
                    <Nav.Link href="/">Used</Nav.Link>
                    <Nav.Link href="/">Car Rentals</Nav.Link>
                    <Nav.Link href="/">Dealers</Nav.Link>
                  </Nav>
                  <Nav.Link href="/signup">
                    <button className={styles.navvBtn}>
                      Sell Your Vehicle
                    </button>
                  </Nav.Link>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </>
    </div>
  );
};

export default Header;
